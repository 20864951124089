import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

// constants
import { useLocale } from "../hooks/useLocale"

// Styling
import colors from "../styles/colors"
import { fontWeights } from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import ContentWrapper from "../components/ContentWrapper"
import Logo from "../components/Logo"
import Link from "../components/atoms/Link"
import Button from "./atoms/Button"
import LocaleSwitcher from "./LocaleSwitcher"
import Linkedin from "./icons/Linkedin"

interface IProps {
  locationPathname: string
}

const content = {
  company: (isDutch: boolean) => [
    {
      label: isDutch ? "Over ons" : "About us",
      href: "/about",
    },
    {
      label: "Resources",
      href: "/resources",
    },
    {
      label: "Blog",
      href: "/blog",
    },
    {
      label: "FAQ",
      href: "/faq",
    },
    {
      label: "Privacy statement",
      href: "/privacy",
    },
  ],
}

const Footer: React.FC<IProps> = ({ locationPathname }) => {
  const { isDutch, getLocalizedLink } = useLocale()

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          prismicHomePage {
            data {
              footer_socials {
                type
                url
              }
            }
          }
          allPrismicDeepDiveTemplate(
            sort: { fields: data___eyebrow_heading, order: ASC }
          ) {
            edges {
              node {
                uid
                lang
                data {
                  heading {
                    text
                  }
                }
              }
            }
          }
          allPrismicGreatforTemplate(
            sort: { fields: data___purpose_label, order: ASC }
          ) {
            edges {
              node {
                uid
                lang
                data {
                  purpose_label
                }
              }
            }
          }
          allPrismicServicesTemplate(
            sort: { fields: data___title, order: ASC }
          ) {
            edges {
              node {
                uid
                lang
                data {
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const footerData = {
          socials: data.prismicHomePage?.data?.footer_socials,
        }

        // Filter deep dives based on current locale
        const deepDives = data.allPrismicDeepDiveTemplate.edges
          .filter((edge: any) =>
            !!isDutch ? edge.node.lang === "nl-nl" : edge.node.lang === "en-nl"
          )
          .map((edge: any) => ({
            label: edge.node.data.heading.text,
            href: `/deepdive/${edge.node.uid}`, // Assuming you have a page for each deep dive
          }))

        // Filter greatFor templates based on current locale
        const greatForTemplates = data.allPrismicGreatforTemplate.edges
          .filter((edge: any) =>
            !!isDutch ? edge.node.lang === "nl-nl" : edge.node.lang === "en-nl"
          )
          .map((edge: any) => ({
            label: edge.node.data.purpose_label,
            href: `/greatfor/${edge.node.uid}`, // Assuming you have a page for each great for
          }))

        // Filter services templates based on current locale
        const servicesTemplates = data.allPrismicServicesTemplate.edges
          .filter((edge: any) =>
            !!isDutch ? edge.node.lang === "nl-nl" : edge.node.lang === "en-nl"
          )
          .map((edge: any) => ({
            label: edge.node.data.title,
            href: `/services/${edge.node.uid}`, // Assuming you have a page for each service
          }))

        return (
          <Container>
            <StyledContentWrapper>
              <LogoSection>
                <Link
                  type="anchor"
                  to="intro"
                  page={getLocalizedLink("/")}
                  hasstyling={false}
                >
                  <StyledLogo simple />
                </Link>
              </LogoSection>

              <FooterMenu>
                <Section>
                  <Title>{isDutch ? "Diensten" : "Services"}</Title>
                  <StyledList>
                    {servicesTemplates.map(
                      (item: { label: string; href: string }) => (
                        <StyledLink
                          key={item.label}
                          type="internal"
                          title={item.label}
                          to={item.href}
                          color={colors.main["100"]}
                        />
                      )
                    )}
                  </StyledList>
                </Section>
                <SectionWrapper>
                  <Section>
                    <Title>{isDutch ? "Bedrijf" : "Company"}</Title>
                    <StyledList>
                      {content.company(isDutch).map((item) => (
                        <StyledLink
                          key={item.label}
                          type="internal"
                          title={item.label}
                          to={item.href}
                          color={colors.main["100"]}
                        />
                      ))}
                    </StyledList>
                    <Section></Section>
                    <Title>
                      {isDutch ? "SUMM is voor" : "SUMM is great for"}
                    </Title>
                    <StyledList>
                      {greatForTemplates.map(
                        (item: { label: string; href: string }) => (
                          <StyledLink
                            key={item.label}
                            type="internal"
                            title={item.label}
                            to={item.href}
                            color={colors.main["100"]}
                          />
                        )
                      )}
                    </StyledList>
                  </Section>
                </SectionWrapper>
                <Section>
                  <Title>Deep dives</Title>
                  <StyledList>
                    {deepDives.map((item: { label: string; href: string }) => (
                      <StyledLink
                        key={item.label}
                        type="internal"
                        title={item.label}
                        to={item.href}
                        color={colors.main["100"]}
                      />
                    ))}
                  </StyledList>
                </Section>

                <DesktopScreen>
                  <ContactusWrapper>
                    <ContactSection>
                      <TextWrapper>
                        <Title>
                          {isDutch ? "Neem contact op" : "Contact us"}
                        </Title>
                        <ContactusTagLine>
                          {isDutch
                            ? "Je kunt ons van alles vragen!"
                            : "You can ask us anything!"}
                        </ContactusTagLine>
                      </TextWrapper>
                      <StyledButton
                        fullWidth={true}
                        href={getLocalizedLink("/contact")}
                        label={
                          isDutch ? "Praat met het team" : "Speak to our team"
                        }
                        variant="primary"
                      />
                    </ContactSection>

                    <AddressSection>
                      <Title>{isDutch ? "Bezoek ons" : "Visit us"}</Title>
                      <Address>
                        Gedempt Hamerkanaal 33 <br /> 1021 KL Amsterdam
                      </Address>
                      {footerData?.socials?.length > 0 && (
                        <div>
                          {footerData.socials.map((item: any) => (
                            <Link
                              key={item.type.toLocaleLowerCase()}
                              type="external"
                              target="_blank"
                              to={item.url}
                              hasstyling={false}
                            >
                              <Linkedin width={24} height={24} />
                            </Link>
                          ))}
                        </div>
                      )}
                    </AddressSection>
                  </ContactusWrapper>
                </DesktopScreen>
              </FooterMenu>

              <MobileScreen>
                <ContactusWrapper>
                  <ContactSection>
                    <TextWrapper>
                      <Title>
                        {isDutch ? "Neem contact op" : "Contact us"}
                      </Title>
                      <ContactusTagLine>
                        {isDutch
                          ? "Je kunt ons van alles vragen!"
                          : "You can ask us anything!"}
                      </ContactusTagLine>
                    </TextWrapper>
                    <StyledButton
                      fullWidth={true}
                      href={getLocalizedLink("/contact")}
                      label={
                        isDutch ? "Praat met het team" : "Speak to our team"
                      }
                      variant="primary"
                    />
                  </ContactSection>

                  <AddressSection>
                    <Title>{isDutch ? "Bezoek ons" : "Visit us"}</Title>
                    <Address>
                      Gedempt Hamerkanaal 33 <br /> 1021 KL Amsterdam
                    </Address>
                    {footerData?.socials?.length > 0 && (
                      <div>
                        {footerData.socials.map((item: any) => (
                          <Link
                            key={item.type.toLocaleLowerCase()}
                            type="external"
                            target="_blank"
                            to={item.url}
                            hasstyling={false}
                          >
                            <Linkedin width={24} height={24} />
                          </Link>
                        ))}
                      </div>
                    )}
                  </AddressSection>
                </ContactusWrapper>
              </MobileScreen>

              <LocalizeSection>
                <StyledLocaleSwitcher
                  context="footer"
                  currentPathname={locationPathname}
                />
                <StyledDate>
                  &copy; 2010 – {new Date().getFullYear()}
                </StyledDate>
              </LocalizeSection>
            </StyledContentWrapper>
          </Container>
        )
      }}
    />
  )
}

const Container = styled.footer`
  background-color: ${colors.white};
`
const StyledLocaleSwitcher = styled(LocaleSwitcher)`
  ${mq.from.M`
  font-size: 18px;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding: 48px 16px;
  gap: 48px;
  ${mq.from.S`
  padding: 64px 48px 64px 48px;
  `}
  ${mq.from.XL`
  padding: 64px 88px 64px 88px;
  `}
`

const LogoSection = styled.div`
  display: flex;
`

const FooterMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  ${mq.from.SM`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 64px;
  `}
  ${mq.from.XL`
  grid-template-columns: repeat(4, 1fr);
  `}
`

const LocalizeSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledDate = styled.p`
  font-size: 16px;
  line-height: 18px;
  font-weight: ${fontWeights.regular};
  color: ${colors.main["100"]};
  margin-bottom: 0px;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLogo = styled(Logo)`
  width: 104px;
`

const Title = styled.p`
  font-size: 18px;
  line-height: 30px;
  font-weight: ${fontWeights.medium};
  color: ${colors.main["100"]};
  letter-space: -1%;
  margin-bottom: 0px;
`

const StyledLink = styled(Link)`
  font-size: 18px;
  line-height: 30px;
  font-weight: ${fontWeights.regular};
  letter-space: -1%;
  color: ${colors.main["100"]};
  text-decoration: none;
  margin-bottom: 0px;

  &::after {
    content: none;
  }

  &:hover {
    color: ${colors.purpleLight};
  }
`

const ContactusWrapper = styled(LocalizeSection)`
  flex-direction: column;
  gap: 48px;
  padding: 0px;
  align-items: flex-start;
  ${mq.from.S`
  flex-direction: row;
  `}
  ${mq.from.XL`
  flex-direction: column;
  `}
`

const MobileScreen = styled.div`
  ${mq.from.XL`
  display: none;
  `}
`

const DesktopScreen = styled.div`
  display: none;

  ${mq.from.XL`
  display: block;
  `}
`

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 16px;
  padding: 24px;
  width: 100%;
  background-color: ${colors.main["700"]};
`

const TextWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`

const ContactusTagLine = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${fontWeights.regular};
  color: ${colors.main["100"]};
  letter-space: -1%;
  margin-bottom: 0px;
`

const AddressSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  width: 100%;
`

const Address = styled.p`
  font-size: 18px;
  line-height: 30px;
  font-weight: ${fontWeights.regular};
  color: ${colors.main["200"]};
  letter-space: -1%;
  margin-bottom: 0px;
`

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-size: 18px;
  line-height: 30px;
  font-weight: ${fontWeights.medium};
  letter-space: -1%;
  color: ${colors.white};
  width: 100%;
`

export default Footer
